@import '../core.scss';

div.product {
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
  transition: ease-in-out .3s;
  background: #fff;
  margin-bottom: 30px;
  padding: 40px 30px 20px 30px;
  height: 100%;
  color: #313437;
  text-align: center;

  a {
    color: map-get($colors, 'danger-50')!important;

    &:hover {
      color: darken(map-get($colors, 'danger-50'), 25%) !important;
      transition: ease-in-out 0.3s;
      cursor: pointer;
    }
  }
}
