@import '../core.scss';

.post-preview {
  box-shadow: 0 1px 2px rgba(43, 59, 93, .3);
  margin: 0 15px 32px 15px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;

  > .image {
    overflow: hidden;
    position: relative;

    ul.tags {
      position: absolute;
      // z-index: 1;
      top: 10px;
      right: 0;
      margin: 0;
      padding: 0;
      white-space: nowrap;

      &:hover {
        max-width: 500px;

        li {
          max-width: 500px;
          width: auto;
          transition: max-width .5s ease;
        }
      }

      @media (max-width: 770px) {
        display: none;
      }

      li {
        list-style-type: none;
        padding: 1px 5px 1px 10px;
        background: #fff;
        border-right: 5px solid map-get($colors, 'primary-50');
        margin: 1px 0 0;
        overflow: hidden;
        border-radius: 28px 0 0 28px;
        font-size: 14px;
        text-transform: lowercase;
        max-width: 32px;

        a span {
          color: black;
          padding-left: 5px;
          text-decoration: none;

          &:hover {
            color: map-get($colors, 'danger-50');
          }
        }
      }
    }

    img {
      transform: scale(1);
      transition: all 1s ease-in-out 0s;
      width: 320px;
      height: 320px;

      &:hover {
        transform: scale(1.03);
      }
    }

    & + .content {
      background: white;
      padding: 20px;

      h3 {
        font-size: 17px;
        font-weight: 600;
        margin-top: 0px;
        text-transform: capitalize;
        height: 50px;

        & + p {
          font-size: 16px;
          color: map-get($colors, "dark");
          margin-bottom: 14px;
          height: 100px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow-y: hidden;
        }
      }

      .tags {
        padding-left: 0px;

        li {
          display: inline;
          font-size: smaller;

          a {
            text-decoration: none;
          }

          &:not(:last-of-type):not(:first-of-type)::after {
            content: ', '
          }
        }

        @media (min-width: 770px) {
          display: none;
        }
      }
    }
  }

  a.view {
    border-radius: 0px;
    border-bottom-right-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 15px;
    text-transform: capitalize;
    transition: ease-in-out 0.3s;
  }
}
