$colors: (
  "primary": #003399,
  "primary-50": #006699,
  "primary-25": #0099ff,
  "info": #0099cc,
  "info-50": #33ccff,
  "danger": #ff4040,
  "danger-50": #cc6699,
  "grey": #999999,
  "dark": #474747,
  "brown": #663333,
  "success": #099
);

@each $name, $hex in $colors {
  .bg-#{$name} {
    background-color: $hex;
  }

  .border-#{$name} {
    border-color: $hex;
  }

  .text-#{$name} {
    color: $hex;
  }

  .btn-#{$name} {
    background-color: $hex!important;
    color: white!important;

    &:active, &:hover {
      background-color: darken($hex, 7.5%)!important;
    }
  }
}

$body-bg: white;

#root #content {
  background: whitesmoke;
  padding-bottom: 30px;
  padding-top: 160px;
  margin-top: null;
  min-height: 90vh;
}

.modal-dialog.wv .modal-body {
  padding: 20px 70px;
}

:root {
  --toastify-toast-width: 420px
}

a {
  color: map-get($colors, 'primary-25');
  &:hover {
    transition: ease-in-out 0.3s;
  }
}

form.search-box {
  position: relative;

  > button {
    position: absolute;
    height: 37px;
    right: 0px;

    background: map-get($colors, 'danger-50');
    border: none;
    border-radius: 0.375rem;
    color: white;
    transition: ease-in-out 0.3s;
    &:hover {
      background-color: darken(#cc6699, 7.5%);
    }
  }

  input:focus {
    border-color: #cc6699;
    box-shadow: unset;
  }
}


.Toastify__toast-container {
  .Toastify__toast-body {
    align-items: start;
    .Toastify__toast-icon {
      margin-top: 5px;
    }
  }
  div p{

    margin-bottom: 0rem;
  }
}

form {
  .form-control {
    background: white;
  }

  label.required::after {
    content: ' *';
    color: map-get($colors, "danger");
    font-weight: 700;
  }
}
