@import '../core.scss';
@import 'bootstrap';

@import url('https://fonts.googleapis.com/css?family=Figtree|Roboto');

body {
  font-family: 'figtree', 'roboto', serif;
  font-size: 20px;
  position: relative;

  a {
    color: map-get($colors, 'primary-50');
    &:hover {
      color: map-get($colors, 'danger-50');
      transition: ease-in-out 0.3s;
    }
  }

  .weave-hr {
    &:first-of-type {
      margin-bottom: 60px;
    }
    &:last-of-type {
      margin-top: 67px;
    }
    margin: '60px 0px'
  }
}

img::selection {
  background: none;
}

.modal-content {
  margin-top: 60px;
  margin-bottom: 65px;
}

// We only want this rule on the index page
div#content {
  padding-top: 0px!important;
}

/* Styles for the various sections on the index page
 *----------------------------------------------------------------------*/
section:not(#top) {
  padding-top: 10vh;
}

section#top {
  // background-image: url("~/src/images/landing.png");
  background-size: cover;
  height: 100vh;

  img {
    max-height: 250px;
  }
}

section#about {
    h2, p.sub {
        text-align: center;
        font-weight: bold;

        &:not(h2) {
          margin-bottom: 30px;
        }
    }

    div.profile {
        flex: 0 0 auto;
        width: auto;
        text-align: center;

        .img-thumbnail {
            max-height: 255px;
            max-width: 255px;
            margin-bottom: 25px;
        }

        @media (max-width: 830px) {
          display: block;
          width: 100%;
          margin-bottom: 20px;
        }
    }

    svg.fa-quote-left {
        color: map-get($colors, 'primary-50');
        font-size: 50px;
    }

    .blockquote-footer {
        margin-top: 20px;
    }
}

section#blog {
  h2,
  p.sub {
    text-align: center;
  }
}

section#book {
  img.book-cover {
    display: block;
    box-shadow: 1px 3px 8px 3px rgba(43, 59, 93, 0.4);
    margin: 0px 30px 15px 0px;
    max-height: 350px;
    text-align: center;

    @media (max-width: 992px) {
      max-height: 350px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 992px) {
    .col-auto {
      width: 100%;
      margin-bottom: 35px;
    }
  }

  h4 {
    margin-bottom: 0px;
    font-weight: bold;

    + p {
      font-style: italic;
      margin-bottom: 10px;
    }
  }

  p:last-of-type {
    margin-bottom: 40px;
  }
}

section#contact {
  padding-bottom: 0;
  padding-top: 60.9px;

  @media (min-width: 2000px) {
    padding-top: 96.9px;
  }

  h2 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 3vh;
  }

  .call-to-action {
    background: white;
    box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
    font-weight: 500;
    padding: 30px 30px 45px;

    img {
      width: auto;
      max-height: 130px;
    }
  }
}

section#what-we-do {
  img.koru {
    margin-bottom: 20px;
  }
  h2,
  p.sub {
    text-align: center;
    font-weight: bold;

    &:not(h2) {
      margin-bottom: 40px;
    }
  }

  p {
    min-height: 90px;
  }

  h3 {
    font-weight: bold;
  }

  a[data-bs-toggle] {
    padding-top: 35px;
  }
}

/**
 * #what-we-do modals
 * -------------------------------------------------------------*/
div#leaders-suitcase .modal-body {
  h5 {
    color: map-get($colors, 'success');
    font-weight: bold;
  }

  .book-cover {
    margin: 0px 30px;
    max-height: 400px;
    box-shadow: 1px 3px 8px 3px rgba(43, 59, 93, 0.4);

    @media (max-width: 992px) {
      max-height: 350px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  ul.quotes {
    // font-size: smaller;
    list-style: none;

    li {
      font-style: italic;
      padding-bottom: 15px;
      &::before {
        content: open-quote;
      }
      &::after {
        content: close-quote;
      }
    }

    & + a {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 992px) {
    .col-auto {
      width: 100%;
      margin-bottom: 35px;
    }
  }
}

div#wayfinding .modal-body {
  h5 {
    color: map-get($colors, 'primary-50');
    font-weight: bold;
  }

  ul.quotes {
    // font-size: smaller;
    list-style: none;

    li {
      font-style: italic;
      padding-bottom: 15px;
      &::before {
        content: open-quote;
      }
      &::after {
        content: close-quote;
      }
    }

    & + a {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  #mirror-of-leadership {
    h4, h5 {
      color: map-get($colors, 'primary-50');

      &:not(h4) {
        font-style: italic;
        font-weight: normal;
      }
    }
  }

  a {
    color: map-get($colors, 'danger-50');
    text-align: center;
    text-decoration: none;
  }

  img#mirrors {
    @media (max-width: 990px) {
      display: block;
      margin: 0px auto 25px;
    }
  }

  :not(p) > a:not(.btn) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

div#design-thinking {
  h5 {
    color: map-get($colors, 'success');
    font-weight: bold;
  }

  span.highlight {
    font-weight: bolder;

    &[data-wv-color='primary'] {
      color: map-get($colors, 'primary');
    }

    &[data-wv-color='pink'] {
      color: map-get($colors, 'danger-50');
    }
  }
}
