@import '../core.scss';

footer {
  background-color: white;
  bottom: 0;
  box-shadow: inset 0 15px 3px -16px #111;   
  min-height: 80px;
  padding: 20px 20px 5px;
  text-align: center;
  width: 100%;
  --bs-gutter-x: 0!important;

  img {
    max-height: 69px;
    width: auto;
  }

  a {
    line-height: 80px;
    text-decoration: none;

    &:hover {
      color: map-get($colors, 'danger-50');
    }
  }

  svg {
    font-size: 30px;
  }
}
