@import '../core.scss';

a.thursday-thinking {
  color: map-get($colors, 'danger-50');
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: darken(#cc6699, 7.5%);
    transition: ease-in-out 0.3s;
  }
}

div.modal#thursday-thinking {
  h4 {
    text-align: left;
    margin-bottom: 1em;
    font-weight: bold;
  }
}