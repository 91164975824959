@import '../core.scss';

nav.navbar {
  background-color: white;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, .4);
  color: #999999;
  transition: ease-in-out 0.3s;

  padding-top: 0px;
  padding-bottom: 0px;

  a.navbar-brand {
    padding-top: 5px;
    padding-bottom: 5px;

    > img {
      max-height: 100px;

      @media (max-width: 1500px) {
        max-height: 70px;
      }

      @media (max-width: 600px) {
        max-height: 60px;
      }
    }
  }

  .nav-link {
    font-size: 17px;
    padding-left: 15px !important;
    padding-right: 15px !important;

    &.active, &:hover {
      color: map-get($colors, 'blue');
      transition: ease-in-out 0.3s;
    }
  }

  &.scrolled {
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, .2);
  }

  &.haswpadminbar {
    @media (min-width: 601px) {
      padding-top: 46px !important;
    }

    @media (min-width: 782px) {
      padding-top: 32px !important;
    }
  }
}

// nav.navbar {

//   // a.navbar-brand {
//   //   padding-top: 0px;
//   //   padding-bottom: 0px;

//   //   >img {
//   //     max-height: 110px;

//   //     @media (max-width: 820px) {
//   //       max-height: 70px;
//   //     }

//   //     @media (max-width: 600px) {
//   //       max-height: 60px;
//   //     }
//   //   }
//   // }

//   .nav-link {
//     color: map-get($colors, 'dark');
//     border-radius: 29px;
//     padding-left: 15px !important;
//     padding-right: 15px !important;
//     margin-top: 5px;
//     margin-bottom: 5px;

//     &.active {
//       color: white;
//       background-color: map-get($colors, 'danger-50');

//       &:hover {
//         color: white;
//         background-color: darken(map-get($colors, 'danger-50'), 10%);
//         transition: ease-in-out 0.3s;
//       }
//     }

//     &:hover {
//       color: white;
//       background-color: map-get($colors, 'danger-50');
//     }

//   }
// }
